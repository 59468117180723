import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";
import TextInput from "clutch/src/TextInput/TextInput.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import PageHeader from "@/shared/PageHeader.jsx";
import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
import { calcRate } from "@/util/helpers.mjs";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";

const BlueFront = "blue-avatar-front.webp";
const OrangeFront = "orange-avatar-front.webp";
const PurpleBackground = "purple-avatar-back.webp";
const PurpleFront = "purple-avatar-front.webp";
const BlueBackground = "blue-avatar-back.webp";
const OrangeBackground = "orange-avatar-back.webp";
const iconPath = `${appURLs.CDN_PLAIN}/blitz/ui/images/icons/`;

const Styled = {
  dataTable: () => css`
    & a {
      color: var(--shade0);
      font-weight: 725;
    }
    & .item-link {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
    & a > div {
      width: var(--sp-18);
      height: var(--sp-7);
      display: inline-table;
    }
    & tr:has(.tier-row.tier-S) td {
      background: #d5a03826;
      .cell {
        color: #d5a038;
      }
    }
    & tr:has(.tier-row.tier-A) td {
      background: #30d9d426;
      .cell {
        color: #30d9d4;
      }
    }
    & tr:has(.tier-row.tier-B) td {
      background: #9dd5d726;
      .cell {
        color: #9dd5d7;
      }
    }
    & tr:has(.tier-row.tier-C) td {
      background: #c1d4d826;
      .cell {
        color: #c1d4d8;
      }
    }
    & tr:has(.tier-row.tier-D) td {
      background: #c1d4d826;
      .cell {
        color: #c1d4d8;
      }
    }

    ${mobile} {
      & a.item-link > span {
        max-width: var(--sp-30);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  highlights: () => css`
    display: flex;
    gap: var(--sp-4);

    & > section {
      flex-grow: 1;
    }

    & > section > .card-frame {
      height: 180px;
    }

    & .stat-title {
      color: #7f838b;
      font-size: 0.813rem;
      line-height: var(--sp-5);
      letter-spacing: -0.009rem;
      color: #e3e5ea80;
      font-weight: 625;
    }
  `,
};

export const TierListLayout = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--sp-4);
  width: 100%;
`;

const weaponStatsRaw = [
  {
    weapon: {
      id: "279",
      longName: "Molot Arms VPO-209 .366 TKM carbine",
      shortName: "VPO-209",
      uuid: "59e6687d86f77411d949b251",
    },
    tier: "S",
    count: 6,
    kills: 8,
    totalShots: 200,
    shotsLanded: 120,
    damageDealt: 4509,
    hitsHead: 50,
  },
  {
    weapon: {
      id: "71",
      longName: ".366 TKM EKO",
      shortName: "EKO",
      uuid: "59e655cb86f77411dc52a77b",
    },
    tier: "A",
    count: 10,
    kills: 2,
    totalShots: 50,
    shotsLanded: 49,
    damageDealt: 1943,
    hitsHead: 42,
  },
  {
    weapon: {
      id: "18",
      longName: "Kalashnikov AK-104 7.62x39 assault rifle",
      shortName: "AK-104",
      uuid: "5ac66d725acfc43b321d4b60",
    },
    tier: "A",
    count: 99,
    kills: 157,
    totalShots: 377,
    shotsLanded: 228,
    damageDealt: 4949,
    hitsHead: 28,
  },
  {
    weapon: {
      id: "39",
      longName: "12/70 5.25mm buckshot",
      shortName: "5.25mm",
      uuid: "5d6e6772a4b936088465b17c",
    },
    tier: "B",
    count: 20,
    kills: 15,
    totalShots: 100,
    shotsLanded: 90,
    damageDealt: 9457,
    hitsHead: 10,
  },
];

const tierOrder = ["S", "A", "B", "C", "D"];

export default function Main() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [searchFilter, setSearchFilter] = useState("");
  const { getWeaponImage } = useWeaponImages();

  const { weaponStats, bestKillsRaid, bestDmgRaid, bestHeadshot } =
    useMemo(() => {
      if (!weaponStatsRaw || weaponStatsRaw instanceof Error) {
        return {
          weaponStats: null,
          bestKillsRaid: null,
          bestDmgRaid: null,
          bestHeadshot: null,
        };
      }

      const weaponStats = weaponStatsRaw.map((stats) => {
        const data = {
          ...stats,
          killsRaid: calcRate(stats.kills, stats.count, 2),
          damageRaid: calcRate(stats.damageDealt, stats.count, 1),
          accuracy: calcRate(stats.shotsLanded, stats.totalShots),
          headshot: calcRate(stats.hitsHead, stats.totalShots),
        };
        return data;
      });
      const bestKillsRaid = weaponStats.sort(
        (a, b) => b.killsRaid - a.killsRaid,
      )[0];
      const bestDmgRaid = weaponStats.sort(
        (a, b) => b.damageRaid - a.damageRaid,
      )[0];
      const bestHeadshot = weaponStats.sort(
        (a, b) => b.headshot - a.headshot,
      )[0];

      return { weaponStats, bestKillsRaid, bestDmgRaid, bestHeadshot };
    }, []);

  const rows = useMemo(() => {
    if (!weaponStats) {
      return [];
    }
    let lastTier: string | undefined;
    return weaponStats
      .filter((stats) => {
        return stats.weapon.longName
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      })
      .sort(
        (a, b) =>
          tierOrder.findIndex((t) => t === a.tier) -
          tierOrder.findIndex((t) => t === b.tier),
      )
      .reduce((acc, stats, i) => {
        if (lastTier !== stats.tier) {
          acc.push([
            {
              value: acc.length,
              display: `Tier ${stats.tier}`,
              className: `tier-row tier-${stats.tier}`,
            },
            {
              value: 0,
            },
            { value: 0 },
            { value: 0 },
            { value: 0 },
            { value: 0 },
          ]);
        }
        lastTier = stats.tier;
        const TierIcon = getTierIcon(stats.tier);
        acc.push([
          {
            value: acc.length,
            display: <TierIcon />,
          },
          {
            value: i,
            display: (
              <a className="item-link" href="#">
                <WeaponImage src={getWeaponImage(stats.weapon.longName)} />
                <span>{stats.weapon.longName}</span>
              </a>
            ),
          },
          {
            value: stats.killsRaid,
            display: stats.killsRaid.toFixed(1),
          },
          {
            value: stats.accuracy,
            display: formatToPercent(language, stats.accuracy, {
              min: 1,
            }),
          },
          {
            value: stats.headshot,
            display: formatToPercent(language, stats.headshot, {
              min: 1,
            }),
          },
          {
            value: stats.damageRaid,
            display: stats.damageRaid.toLocaleString(getLocale()),
          },
        ]);
        return acc;
      }, []);
  }, [getWeaponImage, language, searchFilter, weaponStats]);

  return (
    <TierListLayout>
      <PageHeader imageRound title="Tier List" />
      <Container className="flex column gap-sp-4">
        <div className="flex gap-2">
          <TextInput
            placeholder={t("common:search", "Search")}
            onChange={(e) => setSearchFilter(e.target.value)}
            value={searchFilter}
            Icon={SearchIcon}
          />
          <div className="flex justify-end flex-grow gap-sp-2 align-center">
            <span className="type-subtitle shade2">
              {t("eft:wipe13.0", "Wipe 13.0")}
            </span>
          </div>
        </div>
        <div className={Styled.highlights()}>
          <HighlightStatCard
            image={getWeaponImage()}
            label={bestKillsRaid.weapon.shortName}
            stat={bestKillsRaid.killsRaid.toLocaleString(getLocale())}
            statLabel={t("eft:killsRaid", "Kills / Raid")}
            imageSize={220}
            avatarBack={iconPath + OrangeBackground}
            avatarFront={iconPath + OrangeFront}
          />
          <HighlightStatCard
            image={getWeaponImage()}
            label={bestDmgRaid.weapon.shortName}
            stat={bestDmgRaid.damageRaid.toLocaleString(getLocale())}
            statLabel={t("eft:damageRaid", "Dmg / Raid")}
            imageSize={220}
            avatarBack={iconPath + PurpleBackground}
            avatarFront={iconPath + PurpleFront}
          />
          <HighlightStatCard
            image={getWeaponImage()}
            label={bestHeadshot.weapon.shortName}
            stat={formatToPercent(language, bestHeadshot.headshot, {
              min: 1,
            })}
            statLabel={t("eft:headshot", "Headshot")}
            imageSize={220}
            avatarBack={iconPath + BlueBackground}
            avatarFront={iconPath + BlueFront}
          />
        </div>
        <DataTable
          className={Styled.dataTable()}
          sortable={false}
          sortCol={0}
          sortDir="ASC"
          cols={[
            {
              display: t("eft:tier", "Tier"),
              align: "left",
              primary: true,
            },
            {
              display: t("eft:weapon", "Weapon"),
              align: "left",
              primary: true,
            },
            {
              display: t("eft:killsRaid", "Kills / Raid"),
              align: "right",
              isStat: true,
            },
            {
              display: t("eft:accuracy", "Accuracy"),
              align: "right",
              isStat: true,
            },
            {
              display: t("eft:headshot", "Headshot"),
              align: "right",
              isStat: true,
            },
            {
              display: t("eft:damageRaid", "Dmg / Raid"),
              align: "right",
              isStat: true,
            },
          ]}
          rows={rows}
        />
      </Container>
    </TierListLayout>
  );
}

export function meta() {
  return {
    title: ["eft:meta.tierlist.title", "Escape From Tarkov Tier List"],
    description: [
      "eft:meta.tierlist.description",
      "Get the most up to date rankings for all top weapons by category",
    ],
    subtitle: false,
  };
}
